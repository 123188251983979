<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="../assets/images/logo/logo-symbol-light.png" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="breadcrumb-area rn-bg-color ptb--120 bg_image bg_image--1"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100 pt_sm--40 pt_md--50">
              <h2 class="heading-title">Nauczyciele</h2>
              <ul class="page-list">
                <li v-for="(item, i) in breadcrumbs" :key="i">
                  <router-link :to="item.to" :disabled="item.disabled">{{
                    item.text
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <!-- Start team Area  -->
    <div class="rn-team-area team-area rn-section-gap bg_color--5">
      <v-container>
        <v-row class="row">
          <v-col cols="12">
            <div class="section-title text-center mb--30">
              <h5>Jak się zostaje nauczycielem Metody Feldenkraisa?</h5>
              <p>By zostać nauczycielem Metody Feldenkraisa i uzyskać certyfikat trzeba ukończyć trening,
                  który na przestrzeni <strong>3 – 4 lat</strong> obejmuje około <strong>800 godzin</strong> edukacyjnych.
              </p>
            </div>
          </v-col>
        </v-row>
        <Teachers />
      </v-container>
    </div>
    <!-- Start team Area  -->

    <Footer />
  </div>
</template>

<script>
  import Header from "../components/header/Header";
  import Teachers from "../components/team/Teachers.vue";
  import Footer from "../components/footer/Footer";

  export default {
    components: {
      Header,
      Teachers,
      Footer,
    },
    data() {
      return {
        breadcrumbs: [
          {
            text: "Feldenkrais Polska",
            to: "/",
            disabled: false,
          },
          {
            text: "Nauczyciele",
            to: "/nauczyciele",
            disabled: true,
          },
        ],
      };
    },

    methods: {},
  };
</script>
