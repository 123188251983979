<template>
  <div>
    <!-- Start Filters Area  -->
    <div class="button-group mt--30">
      <v-row class="mt--30">
        <v-col>
            <a
              :data-filter="'all'"
              :class="'rn-button-style--2 btn_solid btn-size-sm'"
            >
                Wszyscy nauczyciele
            </a>
          <a
            v-for="filter in filters"
            :key="filter.id"
            :data-filter=filter.value
            :class="'rn-button-style--2 ' + filter.style + ' btn-size-sm'"
          >
            {{ filter.name }}
          </a>
        </v-col>
      </v-row>
    </div>

    <!-- Start Single Team Area  -->
    <v-row class="mt--30 filtr-container">
      <v-col
        lg="3"
        md="3"
        sm="6"
        cols="6"
        v-for="team in teamContent"
        :key="team.id"
        :data-category=team.filterCategory
        :class="'filtr-item'"
      >
        <div class="team-static">
          <div class="thumbnail">
            <router-link :to="'nauczyciel/'+ team.slug">
              <img :src="team.photo" alt="" />
            </router-link>
          </div>
          <div class="inner">
            <div class="content">
              <h4 class="title">
                <router-link :to="'nauczyciel/'+ team.slug">
                  {{ team.name }} {{ team.surname }}
                </router-link>
              </h4>
              <p class="designation">
                <router-link :to="'nauczyciel/'+ team.slug">
                  {{ team.entitlement }}
                </router-link>
              </p>
  <!--            <p class="designation">{{ team.voivodeship }}</p>-->
              <div class="blog-btn">
                <router-link :to="'nauczyciel/'+ team.slug" class="rn-btn">
                  Bio
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <!-- End Single Team Area  -->
    </v-row>
  </div>
</template>

<script>
  // import SocialIcon from "../../components/social/Social";
  import Filterizr from 'filterizr'
  import axios from 'axios';

  export default {
    data() {
      return {
        teamContent: [],
        filters: [],
      };
    },
    mounted() {
      axios({
          url: process.env.VUE_APP_API_ENDPOINT,
          method: 'post',
          data: {
            query: `
                query getAllTeacher
                {
                    teachers {
                        slug,
                        name,
                        surname,
                        entitlement,
                        online,
                        voivodeship,
                        cities {
                          name
                        },
                        photo
                   }
                }
            `
          }
      })
      .then(response => {
          this.teamContent = response.data.data.teachers;

          let counter = 0;
          let filters = [];

          // zbudować filtry tylko z dostepnych parametrów
          this.teamContent.forEach((teacher) => {
             teacher.filterCategory = [];

             if (teacher.online !== undefined && teacher.online === true) {
                 teacher.filterCategory.push('online');

                 if (filters['online'] === undefined) {
                     filters['online'] = {
                         id: 0,
                         name: "Lekcje Online",
                         value: 'online',
                         type: 'online',
                         style: 'btn_solid'
                     }
                 }
             }

              if (teacher.voivodeship !== undefined) {
                  teacher.voivodeship.forEach(voivodeship => {
                      teacher.filterCategory.push(voivodeship);

                      if (filters[voivodeship] === undefined) {
                          ++counter;
                          filters[voivodeship] = {
                              id: counter,
                              name: voivodeship,
                              value: voivodeship,
                              type: 'voivodeship',
                              style: 'btn_border'
                          }
                      }
                  });
              }

              if (teacher.cities !== undefined) {
                  teacher.cities.forEach(city => {
                      teacher.filterCategory.push(city.name);

                      if (filters[city.name] === undefined) {
                          ++counter;
                          filters[city.name] = {
                              id: counter,
                              name: city.name,
                              value: city.name,
                              type: 'city',
                              style: 'btn_border btn_border--dark'
                          }
                      }
                  });
              }
          });

          // zniszczenie kluczy
          let compactFilters = [];
          for (let key in filters) {
              compactFilters.push(filters[key])
          }

          // sortowanie w kolejnosci dodania: online, wojewodztwa, miasta
          compactFilters = compactFilters.sort((filtrA, filtrB) => {
              // najpierw przycisk od lekcji online
              if (filtrA.type === 'online') {
                  return false;
              }
              if (filtrB.type === 'online') {
                  return true;
              }
              // wojewodztwa przed miastami
              if (filtrB.type === 'city' && filtrA.type === 'voivodeship') {
                  return false;
              }
              // miasta za wojewodztwami
              if (filtrA.type === 'city' && filtrB.type === 'voivodeship') {
                  return true;
              }

              return filtrA.name >= filtrB.name
          });

          // console.log('FILTERS', compactFilters)
          this.filters = compactFilters;

          // zamienic filtry na tablice
          this.teamContent.forEach(teacher => {
              // console.log('TEACHER FILTERS', teacher.filterCategory);
              teacher.filterCategory = teacher.filterCategory.join(', ')
          });
      })
      .finally(() => {
          const options = {
              layout: 'sameHeight'
          };
          this.filter = new Filterizr('.filtr-container', options);
      })
    }
  };
</script>
